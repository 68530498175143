import { FC, useEffect, useRef, useState } from "react";

import styles from "./Files.module.scss";

import { IFile, SavePolicy } from "../../hooks/useArchive";
import { File } from "../index";
import { notification, Spin } from "antd";

interface FileProps {
  files: IFile[];
  onRename: (filename: string, destination: string) => void;
  onReplace: (filename: string, destination: string) => void;
  setCopyFile: (filename: string) => void;
  onDelete: (filename: string) => void;
  onAdd: (file: { file: File; savePolicy: SavePolicy }[]) => Promise<void>;
}

const Files: FC<FileProps> = ({
  files,
  onDelete,
  onRename,
  setCopyFile,
  onReplace,
  onAdd,
}) => {
  const filesRef = useRef<HTMLDivElement>(null);
  const [addLoading, setAddLoading] = useState(false);

  useEffect(() => {
    if (!filesRef.current) {
      return;
    }

    const body = document.body;

    const uploadFileList = async (files: FileList) => {
      const filesWithoutFolders = Array.from(files).filter((file) => file.type);

      if (files.length !== filesWithoutFolders.length) {
        notification.error({ message: "Можна перетягувати лише файли" });
      }

      setAddLoading(true);
      await onAdd(
        filesWithoutFolders.map((file) => ({ file, savePolicy: "reject" }))
      );
      setAddLoading(false);
    };

    const drop = (e: DragEvent) => {
      e.stopPropagation();
      e.preventDefault();

      if (!e.dataTransfer) {
        return;
      }
      const files = e.dataTransfer.files;

      uploadFileList(files);
    };

    const paste = (e: ClipboardEvent) => {
      const files = e.clipboardData?.files;
      if (!files) {
        return;
      }

      uploadFileList(files);
    };

    body.addEventListener("paste", paste);
    body.addEventListener("drop", drop);

    return () => {
      body.removeEventListener("drop", drop);
      body.removeEventListener("paste", paste);
    };
  }, [filesRef.current]);

  return (
    <div className={styles.files} ref={filesRef}>
      {files.map((file) => (
        <File
          key={file.filename}
          file={file}
          onRename={onRename}
          onReplace={onReplace}
          setCopyFile={setCopyFile}
          onDelete={onDelete}
        />
      ))}
      {addLoading && (
        <div className={styles.loaderContainer}>
          <Spin />
        </div>
      )}
    </div>
  );
};

export default Files;
