import makeRequest from "./makeRequest";
import { apiBaseurl } from "./baseurl";
import { IFile, SavePolicy } from "../hooks/useArchive";

export const FilesApi = {
  getFiles: async (
    filename: string | undefined = undefined
  ): Promise<IFile[]> => {
    const { data } = await makeRequest<IFile[]>({
      url: apiBaseurl + "/files",
      params: filename && {
        filename,
      },
    });
    return data;
  },

  createDirectory: async (filename: string): Promise<IFile> => {
    const { data } = await makeRequest<IFile>({
      method: "post",
      url: apiBaseurl + "/files",
      params: {
        filename,
        "save-policy": "reject",
        directory: true,
      },
    });
    return data;
  },

  createFile: async (
    filename: string,
    file: File,
    savePolicy: SavePolicy = "reject"
  ): Promise<IFile> => {
    const { data } = await makeRequest<IFile>({
      method: "post",
      url: apiBaseurl + "/files",
      params: {
        filename,
        "save-policy": savePolicy,
        directory: false,
      },
      data: file,
      headers: {
        "Content-Type": file.type,
      },
    });
    return data;
  },

  editFile: async (filename: string, file: File): Promise<IFile> => {
    const { data } = await makeRequest<IFile>({
      method: "post",
      url: apiBaseurl + "/files",
      params: {
        filename,
        "save-policy": "replace",
        directory: false,
      },
      data: file,
      headers: {
        "Content-Type": file.type,
      },
    });
    return data;
  },

  copy: async (
    filename: string,
    destination: string,
    savePolicy: Exclude<SavePolicy, "rename">
  ): Promise<IFile> => {
    const { data } = await makeRequest<IFile>({
      method: "put",
      url: apiBaseurl + "/files",
      params: {
        filename,
        destination,
        "save-policy": savePolicy,
        move: false,
      },
    });

    return data;
  },

  replace: async (
    filename: string,
    destination: string,
    savePolicy: Exclude<SavePolicy, "rename">
  ): Promise<void> => {
    await makeRequest<IFile>({
      method: "put",
      url: apiBaseurl + "/files",
      params: {
        filename,
        destination,
        "save-policy": savePolicy,
        move: true,
      },
    });
  },

  rename: async (filename: string, destination: string): Promise<IFile> => {
    const { data } = await makeRequest<IFile>({
      method: "put",
      url: apiBaseurl + "/files",
      params: {
        filename,
        destination,
        "save-policy": "reject",
        move: true,
      },
    });

    return data;
  },

  delete: async (filename: string): Promise<void> => {
    await makeRequest({
      method: "delete",
      url: apiBaseurl + "/files",
      params: {
        filename,
      },
    });
  },
};
