import { FC, MouseEvent, useEffect, useState } from "react";

import { isImageFileName } from "../../utils/isImageFileName";
import { decodeFromBase64 } from "../../utils/base64";
import { FileApi } from "../../api/file";

interface ThumbnailProps {
  fileUrl: string;
  onClick?: (e: MouseEvent) => void;
}

const Thumbnail: FC<ThumbnailProps> = ({ fileUrl, onClick }) => {
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    (async () => {
      if (isImageFileName(decodeFromBase64(fileUrl))) {
        const fileData = await FileApi.getFile(fileUrl, true);
        if (fileData.type.includes("image")) {
          setImageUrl(URL.createObjectURL(fileData));
        }
      }
    })();
  }, [fileUrl]);

  return <>{imageUrl && <img src={imageUrl} alt="" onClick={onClick} />}</>;
};

export default Thumbnail;
