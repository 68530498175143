import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Space } from "antd";

import { FilesApi } from "../../api/files";
import { FileApi } from "../../api/file";
import { ImageEditor, ImageViewer } from "../../components";
import { base64toFile } from "../../utils/base64imageToFile";
import { decodeFromBase64, encodeToBase64 } from "../../utils/base64";
import { isMobile } from "../../utils/isMobile";

const FileEditor: FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [imageUrl, setImageUrl] = useState("");
  const [getFileLoading, setGetFileLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const filePath = params["*"];
        if (filePath) {
          setGetFileLoading(true);
          const file = await FileApi.getFile(filePath);
          if (file.type.includes("image")) {
            setImageUrl(URL.createObjectURL(file));
          }
        }
      } finally {
        setGetFileLoading(false);
      }
    })();
  }, [params]);

  const handleSave = async (fileBase64: string) => {
    if (!params["*"]) {
      return;
    }
    const filepath = decodeFromBase64(params["*"]);
    const directory = filepath.split("\\").slice(0, -1).join("\\");
    const name = filepath.split("\\").at(-1);
    const file = base64toFile(
      fileBase64.slice(22),
      fileBase64.slice(5, 14),
      name || ""
    );
    try {
      setLoading(true);
      await FilesApi.editFile(params["*"], file);
      navigate(`/files/${encodeToBase64(directory)}`);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    if (!params["*"]) {
      return;
    }
    const filepath = decodeFromBase64(params["*"]);
    const directory = filepath.split("\\").slice(0, -1).join("\\");

    navigate(`/files/${encodeToBase64(directory)}`);
  };

  if (getFileLoading) {
    return <span>...loading</span>;
  }

  return (
    <>
      {imageUrl &&
        (isMobile() ? (
          <Space style={{ width: "100%", height: "100%" }} direction="vertical">
            <Button onClick={handleBack}>Назад</Button>
            <img width="100%" src={imageUrl} alt="" />
          </Space>
        ) : isEditing ? (
          <ImageEditor
            fileUrl={imageUrl}
            loading={loading}
            onSave={handleSave}
            onBack={handleBack}
          />
        ) : (
          <ImageViewer
            fileUrl={imageUrl}
            onBack={handleBack}
            onEdit={() => setIsEditing(true)}
          />
        ))}
    </>
  );
};

export default FileEditor;
