import { FC, KeyboardEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Select, Space, Spin, Switch } from "antd";
import {
  FileOutlined,
  FolderOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import styles from "./SearchFile.module.scss";

import { SearchApi } from "../../api/search";
import { decodeFromBase64, encodeToBase64 } from "../../utils/base64";
import { getFilenameName } from "../../utils/getFilenameName";
import Thumbnail from "../Thumbnail";
import { FoundFile } from "../../api/search";

const { Option } = Select;

const SearchFile: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<FoundFile[]>([]);
  const [searchType, setSearchType] = useState<"global" | "directory">(
    "global"
  );

  const handleSearch = async () => {
    try {
      setLoading(true);
      let filename = encodeToBase64(value);
      if (searchType === "directory") {
        const directory = location.pathname.slice(7);
        if (directory) {
          filename = encodeToBase64(
            `${decodeFromBase64(directory)}\\${decodeFromBase64(filename)}`
          );
        }
      }
      const files = await SearchApi.search(filename);
      setFiles(files);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (filename: string | undefined) => {
    if (!filename) {
      return;
    }

    const file = files.find((f) => f.filename === filename);

    if (!file) {
      return;
    }

    let path: string;

    if (file.directory) {
      path = file.filename;
      navigate("/files/" + file.filename);
    } else {
      const directory = decodeFromBase64(filename)
        .split("\\")
        .slice(0, -1)
        .join("\\");
      path = encodeToBase64(directory);
    }

    navigate("/files/" + path);
    setFiles([]);
  };

  const handleThumbnailClick = (filename: string) => {
    navigate("/file/" + filename);
  };

  const handleInputKeyDown = (e: KeyboardEvent) => {
    if (e.code === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      handleSearch();
    }
  };

  const handleSearchTypeSwitch = (check: boolean) => {
    if (check) {
      setSearchType("global");
    } else {
      setSearchType("directory");
    }
  };

  return (
    <Space>
      <Select
        className={styles.input}
        suffixIcon={
          loading ? undefined : (
            <Button
              className={styles.search}
              icon={<SearchOutlined />}
              onClick={handleSearch}
            />
          )
        }
        placeholder="Пошук"
        filterOption={false}
        showSearch
        loading={loading}
        notFoundContent={loading ? <Spin size="small" /> : null}
        autoClearSearchValue
        value={""}
        searchValue={value}
        onSearch={setValue}
        onInputKeyDown={handleInputKeyDown}
        onSelect={handleSelect}
        listHeight={400}
      >
        {files.map((file) => (
          <Option key={file.filename} value={file.filename}>
            <div className={styles.file}>
              <Space className={styles.fileInfo}>
                {file.directory ? <FolderOutlined /> : <FileOutlined />}
                {searchType === "global" ? (
                  <div className={styles.fileName}>
                    {decodeFromBase64(file.filename)}
                  </div>
                ) : (
                  <div className={styles.fileName}>
                    {getFilenameName(file.filename)}
                  </div>
                )}
              </Space>
              {!file.directory && (
                <Thumbnail
                  fileUrl={file.filename}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleThumbnailClick(file.filename);
                  }}
                />
              )}
            </div>
          </Option>
        ))}
      </Select>
      <Space>
        <Switch
          className={styles.searchSwitch}
          checked={searchType === "global"}
          onChange={handleSearchTypeSwitch}
        />
        <Space className={styles.switchLabel} direction="vertical">
          <span
            style={searchType === "global" ? { color: "#1677ff" } : undefined}
          >
            Глобальний пошук
          </span>
          <span
            style={
              searchType === "directory" ? { color: "#1677ff" } : undefined
            }
          >
            Пошук по директорії
          </span>
        </Space>
      </Space>
    </Space>
  );
};

export default SearchFile;
