import { apiBaseurl } from "./baseurl";
import makeRequest from "./makeRequest";

export interface IDCInfo {
  welcomeText: string;
  phones: string;
}

export const DCInfoApi = {
  getInfo: async () => {
    const { data } = await makeRequest<IDCInfo>({
      url: apiBaseurl + "/info",
    });
    return data;
  },
};
