import { FC } from "react";
import { Link } from "react-router-dom";
import { useDrop } from "react-dnd";

interface PathItemProps {
  path: string;
  link: string;
}

const PathItem: FC<PathItemProps> = ({ path, link }) => {
  const [, drop] = useDrop(() => ({
    accept: "type 1",
    drop: () => ({ name: link }),
  }));

  return (
    <>
      /
      <Link ref={drop} to={`/files/${link}`}>
        {path}
      </Link>
    </>
  );
};

export default PathItem
