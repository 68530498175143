import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Button, Modal, notification, Space } from "antd";

import { FilesApi } from "../api/files";
import { decodeFromBase64, encodeToBase64 } from "../utils/base64";
import { getFilenameName } from "../utils/getFilenameName";
import { FileApi } from "../api/file";

export interface IFile {
  filename: string;
  directory: boolean;
  dateModified: string;
  size: string;
}

const { confirm } = Modal;

export type SavePolicy = "reject" | "rename" | "replace";

const NAME_IS_RESERVED = "Файл з таким ім'ям вже існує!";
const NAME_IS_RESERVED_RENAME =
  "Неможливо змінити назву файлу. Файл з такою назвою вже існує!";

const useArchive = () => {
  const [files, setFiles] = useState<IFile[]>([]);
  const [getFilesLoading, setGetFilesLoading] = useState(true);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [copyFile, setCopyFile] = useState<string>();
  const { pathname } = useLocation();

  useEffect(() => {
    (async () => {
      try {
        const files = await FilesApi.getFiles(pathname.slice(7));
        setFiles(files);
      } catch (e) {
      } finally {
        setGetFilesLoading(false);
      }
    })();
  }, [pathname]);

  const createDirectory = async (
    name: string
  ): Promise<{
    status: "error" | "success";
    message?: string;
  }> => {
    try {
      const directory = pathname.slice(7);
      const filename = directory
        ? encodeToBase64(`${decodeFromBase64(directory)}\\${name}`)
        : encodeToBase64(name);
      const file = await FilesApi.createDirectory(filename);
      setFiles((prev) => [...prev, file]);
      return {
        status: "success",
      };
    } catch (e: any) {
      console.log(e.message);
      return {
        status: "error",
        message: e.message,
      };
    }
  };

  const addFiles = async (files: { file: File; savePolicy: SavePolicy }[]) => {
    try {
      const res = await Promise.allSettled(
        files.map((fileData) => {
          const directory = pathname.slice(7);
          const filename = directory
            ? encodeToBase64(
                `${decodeFromBase64(directory)}\\${fileData.file.name}`
              )
            : encodeToBase64(fileData.file.name);
          return FilesApi.createFile(
            filename,
            fileData.file,
            fileData.savePolicy
          );
        })
      );

      // change save policy of rejected files
      const rejected = res.filter((item) => item.status === "rejected");

      if (rejected.length) {
        const withReservedNames = [];
        for (let i = 0; i < rejected.length; i++) {
          const item = rejected[i];
          const message = (item as PromiseRejectedResult).reason.message;
          if (message === NAME_IS_RESERVED) {
            const savePolicy = await setSavePolicy(
              {
                reject: true,
                replace: true,
                rename: true,
              },
              `Файл з ім'ям "${files[i].file.name}" вже існує!`
            );
            if (savePolicy !== "reject") {
              withReservedNames.push({
                file: files[i].file,
                savePolicy,
              });
            }
          } else {
            notification.error(message);
          }
        }

        if (withReservedNames.length) {
          await addFiles(withReservedNames);
        }
      }

      // clear all files with same names (in case of 'replace' policy)
      const resolvedFiles = res
        .filter((item) => item.status === "fulfilled")
        .map((item) => (item as PromiseFulfilledResult<IFile>).value);

      setFiles((prev) =>
        prev.filter(
          (f) => !resolvedFiles.some((file) => file.filename === f.filename)
        )
      );

      resolvedFiles.forEach((file) =>
        notification.success({
          message: `Зображення ${getFilenameName(
            file.filename
          )} успішно завантажене`,
        })
      );

      // add files
      setFiles((prev) => [...prev, ...resolvedFiles]);
    } catch (e: any) {
      console.log(e.message);
    }
  };

  const rename = async (filename: string, destination: string) => {
    try {
      setEditLoading(true);
      const file = await FilesApi.rename(filename, destination);
      setFiles((prev) =>
        prev.map((f) => {
          if (f.filename === filename) {
            return file;
          }
          return f;
        })
      );
    } catch (e: any) {
      console.log(e.message);
      notification.error({
        message: e.message,
      });
    } finally {
      setEditLoading(false);
    }
  };

  const replace = async (
    filename: string,
    destination: string,
    savePolicy: Exclude<SavePolicy, "rename"> = "reject"
  ) => {
    try {
      setEditLoading(true);
      await FilesApi.replace(filename, destination, savePolicy);
      setFiles((prev) => prev.filter((f) => f.filename !== filename));
    } catch (e: any) {
      console.log(e.message);
      if (e.message === NAME_IS_RESERVED) {
        const savePolicy = await setSavePolicy({ reject: true, replace: true });
        if (savePolicy === "replace") {
          replace(filename, destination, "replace");
        }
      }
    } finally {
      setEditLoading(false);
    }
  };

  const copyFileWithRename = async (filename: string) => {
    try {
      const extension = decodeFromBase64(filename).split(".").at(-1);

      const blob = await FileApi.getFile(filename);
      const file = new File(
        [blob],
        `${window.crypto.randomUUID()}.${extension}`,
        {
          type: blob.type,
        }
      );
      await addFiles([{ file, savePolicy: "reject" }]);
      setCopyFile(undefined);
    } catch (e: any) {
      notification.error({
        message: e.message,
      });
    }
  };

  const copy = async (savePolicy: Exclude<SavePolicy, "rename"> = "reject") => {
    if (!copyFile) {
      return;
    }
    const filename = decodeFromBase64(copyFile).split("\\").at(-1);
    if (!filename) {
      return;
    }
    const directory = pathname.slice(7);
    const destination = directory
      ? encodeToBase64(`${decodeFromBase64(directory)}\\${filename}`)
      : encodeToBase64(filename);
    try {
      setEditLoading(true);
      if (destination === copyFile) {
        copyFileWithRename(copyFile);
        return;
      }
      const file = await FilesApi.copy(copyFile, destination, savePolicy);
      if (directory !== destination) {
        setFiles((prev) =>
          prev.filter(
            (f) => decodeFromBase64(f.filename).split("\\").at(-1) !== filename
          )
        );
        setFiles((prev) => [...prev, file]);
      }
      setCopyFile(undefined);
    } catch (e: any) {
      console.log(e.message);
      if (
        e.message === NAME_IS_RESERVED_RENAME ||
        e.message === NAME_IS_RESERVED
      ) {
        const savePolicy = await setSavePolicy({
          reject: true,
          replace: true,
          rename: true,
        });
        if (savePolicy === "replace") {
          copy("replace");
        } else if (savePolicy === "rename") {
          copyFileWithRename(copyFile);
        } else {
          setCopyFile(undefined);
        }
      } else {
        notification.error({
          message: e.message,
        });
      }
    } finally {
      setEditLoading(false);
    }
  };

  const setSavePolicy = (
    policies: { rename?: boolean; replace?: boolean; reject?: boolean } = {
      rename: false,
      replace: false,
      reject: false,
    },
    title: string = NAME_IS_RESERVED_RENAME
  ): Promise<SavePolicy> => {
    return new Promise((res) => {
      const { destroy } = confirm({
        width: 600,
        title,
        onCancel: () => {
          res("reject");
          destroy();
        },
        footer: (
          <Space
            style={{ marginTop: 10, width: "100%", justifyContent: "end" }}
          >
            {policies.rename && (
              <Button
                onClick={() => {
                  res("rename");
                  destroy();
                }}
              >
                зберегти під новим ім'ям
              </Button>
            )}
            {policies.replace && (
              <Button
                onClick={() => {
                  res("replace");
                  destroy();
                }}
              >
                замінити
              </Button>
            )}
            {policies.reject && (
              <Button
                onClick={() => {
                  res("reject");
                  destroy();
                }}
              >
                відмовитися від збереження
              </Button>
            )}
          </Space>
        ),
      });
    });
  };

  const deleteFile = async (filename: string) => {
    try {
      setDeleteLoading(true);
      await FilesApi.delete(filename);
      setFiles((prev) => prev.filter((f) => f.filename !== filename));
    } catch (e: any) {
      console.log(e.message);
    } finally {
      setDeleteLoading(false);
    }
  };

  return {
    files,
    getFilesLoading,
    addFiles,
    rename,
    replace,
    copyFile,
    setCopyFile,
    copy,
    editLoading,
    deleteFile,
    deleteLoading,
    createDirectory,
  };
};

export default useArchive;
