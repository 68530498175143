import React, { FC, useEffect, useRef, useState } from "react";

import { Button, Slider } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

import styles from "./ImageViewer.module.scss";
import ImageMagnifier from "../ImageMagnifier";

interface ImageViewerProps {
  fileUrl: string;
  onBack: () => void;
  onEdit: () => void;
}

const ImageViewer: FC<ImageViewerProps> = ({ fileUrl, onBack, onEdit }) => {
  const [zoom, setZoom] = useState<number>(0);

  const zoomPlusButtonClick = () => {
    setZoom((prev: number) => (prev + 15 >= 100 ? 100 : prev + 15));
  };

  const zoomMinusButtonClick = () => {
    setZoom((prev: number) => (prev - 15 <= 0 ? 0 : prev - 15));
  };

  const sliderRef = useRef<any>(null);

  useEffect(() => {
    const slider = sliderRef.current;

    if (!slider) {
      return;
    }
    const handleScroll = (e: WheelEvent) => {
      slider.focus = false;
      if (e.deltaY > 0) {
        setZoom((prev: number) => (prev - 5 <= 0 ? 0 : prev - 5));
      } else if (e.deltaY < 0) {
        setZoom((prev: number) => (prev + 5 >= 100 ? 100 : prev + 5));
      }
    };
    const handleClick = () => {
      sliderRef.current.sliderRef
        .querySelectorAll('[role="slider"]')
        .forEach((slider: any) => {
          slider.blur();
        });
    };
    slider.sliderRef?.addEventListener("wheel", handleScroll);
    slider.sliderRef?.addEventListener("click", handleClick);
    return () => {
      slider.sliderRef?.removeEventListener("wheel", handleScroll);
      slider.sliderRef?.removeEventListener("click", handleClick);
    };
  }, [sliderRef.current]);

  return (
    <div className={styles.container}>
      <div className={styles.zoomButtons}>
        <Button onClick={onBack}>Назад</Button>
        <Button onClick={onEdit}>Редагувати</Button>
        <Button
          type="primary"
          icon={<MinusOutlined />}
          onClick={zoomMinusButtonClick}
        />
        <Slider
          ref={sliderRef}
          className={styles.zoomSlider}
          value={zoom}
          onChange={setZoom}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={zoomPlusButtonClick}
        />
      </div>
      <ImageMagnifier
        style={{
          height: 40 * (zoom / 100 + 2) + "vh",
        }}
        src={fileUrl}
      />
    </div>
  );
};

export default ImageViewer;
