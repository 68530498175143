import makeRequest from "./makeRequest";
import { apiBaseurl } from "./baseurl";

export interface FoundFile {
  directory: boolean;
  filename: string;
}

export const SearchApi = {
  search: async (filename: string): Promise<FoundFile[]> => {
    const { data } = await makeRequest<FoundFile[]>({
      url: apiBaseurl + "/search",
      params: {
        filename,
      },
    });

    // @ts-ignore
    return data as Blob;
  },
};
