import axios from "axios";
import { MakeRequest, ResponseData } from "./types";
import { clearAuthToken } from "../utils/token";

axios.interceptors.request.use(function (config) {
  const data = localStorage.getItem("token");
  if (data && config.headers) {
    config.headers.token = JSON.parse(data);
  }

  return config;
});

export default async <R>({
  url = "/",
  baseURL = process.env.REACT_APP_SERVER_URL,
  method = "get",
  params = {},
  data,
  headers = {},
  responseType = "json",
}: MakeRequest): Promise<ResponseData<R>> => {
  try {
    const response = await axios({
      url,
      baseURL,
      method,
      params,
      data,
      headers,
      responseType,
    });
    if (response.data.status === "error") {
      throw new Error(response.data.data);
    }

    return response.data;
  } catch (e: any) {
    if (e.response?.status === 401 || e.message === "Не вірний токен!") {
      clearAuthToken();
      window.location.href = "/login";
    }
    throw new Error(e.message || "Невідома помилка");
  }
};
