import { FC } from "react";
import { useLocation } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import { Button, Select, Space } from "antd";
import { ReconciliationOutlined } from "@ant-design/icons";

import styles from "./Archive.module.scss";

import useArchive from "../../hooks/useArchive";
import { Files, SearchFile } from "../../components";
import useSortFiles from "./useSortFiles";
import { BackButton, NewDirectory, NewFiles, PathItem } from "./components";
import { decodeFromBase64, encodeToBase64 } from "../../utils/base64";

const { Option } = Select;

const Archive: FC = () => {
  const { pathname } = useLocation();
  const {
    files,
    getFilesLoading,
    createDirectory,
    addFiles,
    rename,
    replace,
    copyFile,
    setCopyFile,
    copy,
    deleteFile,
  } = useArchive();
  const { sortedFiles, setSort } = useSortFiles(files);

  const route =
    pathname === "/"
      ? []
      : decodeFromBase64(
          pathname.replace("/file/", "").replace("/files/", "")
        ).split("\\");

  return getFilesLoading ? (
    <span>loading...</span>
  ) : (
    <DndProvider backend={HTML5Backend}>
      <Space size="large" direction="vertical">
        <SearchFile />
        <Space className={styles.actions}>
          <Select
            className={styles.sort}
            placeholder="Сортування"
            allowClear
            onSelect={setSort}
            onClear={() => setSort(undefined)}
          >
            <Option value="old">від старіших</Option>
            <Option value="new">від новіших</Option>
            <Option value="alphabet">Ім'я</Option>
          </Select>
          <NewDirectory onAdd={createDirectory} />
          <NewFiles onAdd={addFiles} />
          {copyFile && (
            <Button icon={<ReconciliationOutlined />} onClick={() => copy()}>
              Вставити
            </Button>
          )}
        </Space>
        <span style={{ fontSize: 16 }}>
          {route.length > 0 && !!route[0] && (
            // use random key in order to always update component's useDrop hook
            <BackButton key={Math.random()} route={route} />
          )}
          {route.map((path, i) => (
            <PathItem
              key={path}
              path={path}
              link={encodeToBase64(route.slice(0, i + 1).join("\\"))}
            />
          ))}
        </span>
        <Files
          files={sortedFiles}
          onRename={rename}
          onReplace={replace}
          setCopyFile={setCopyFile}
          onAdd={addFiles}
          onDelete={deleteFile}
        />
      </Space>
    </DndProvider>
  );
};

export default Archive;
