import { FC } from "react";
import dayjs from "dayjs";

import { IFile } from "../../../../hooks/useArchive";
import { getFilenameName } from "../../../../utils/getFilenameName";

interface TooltipContentProps {
  file: IFile;
}

const TooltipContent: FC<TooltipContentProps> = ({ file }) => {
  const name = getFilenameName(file.filename);

  return (
    <>
      <div>
        Дата модифікації:{" "}
        {dayjs(new Date(file.dateModified)).format("DD.MM.YYYY HH:mm")}
      </div>
      <div>Назва: {name}</div>
      {!file.directory && <div>Розмір: {file.size}</div>}
    </>
  );
};
export default TooltipContent;
