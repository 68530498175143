import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Alert, Button, Form, Input } from "antd";
import Title from "antd/lib/typography/Title";

import styles from "./LoginPage.module.scss";

import { clearAuthToken, saveAuthToken } from "../../utils/token";
import { AuthApi } from "../../api/auth";
import IntroCarousel from "./Carousel";
import { DCInfo }  from "../../components";

const LoginPage: FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onFinish = (values: any) => {
    (async () => {
      try {
        setLoading(true);
        const { token, expires } = await AuthApi.login(
          values.login,
          values.password
        );
        saveAuthToken(token, expires);
        setLoading(false);
        navigate("/");
      } catch (e: any) {
        setLoading(false);
        clearAuthToken();
        setError(e.message);
      }
    })();
  };

  return (
    <div className={styles.container}>
      <IntroCarousel />
      <div className={styles.formContainer}>
        <Form name="login" className={styles.form} onFinish={onFinish}>
          <DCInfo centered />
          <Title level={4} className={styles.title}>
            Авторизація
          </Title>
          <Form.Item
            label="Логін"
            name="login"
            rules={[{ required: true, message: "Заповніть поле" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Пароль"
            name="password"
            rules={[{ required: true, message: "Заповніть поле" }]}

          >
            <Input.Password />
          </Form.Item>

          {!!error && (
            <Alert
              className={styles.errorMessage}
              message={error}
              type="error"
            />
          )}

          <Form.Item>
            <Button
              loading={loading}
              className={styles.submit}
              type="primary"
              htmlType="submit"
            >
              Ввійти
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;
