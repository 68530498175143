import { FC } from "react";
import { Link } from "react-router-dom";
import { useDrop } from "react-dnd";

import { RollbackOutlined } from "@ant-design/icons";
import { encodeToBase64 } from "../../../../utils/base64";

interface BackButtonProps {
  route: string[];
}

const BackButton: FC<BackButtonProps> = ({ route }) => {
  const [, drop] = useDrop(() => ({
    accept: "type 1",
    drop: () => ({ name: encodeToBase64(route.slice(0, -1).join("\\")) }),
  }));

  const link = encodeToBase64(route.slice(0, -1).join("\\"));

  return (
    <Link ref={drop} style={{ marginRight: 10 }} to={`/files/${link}`}>
      <RollbackOutlined />
    </Link>
  );
};

export default BackButton;
