export const encodeToBase64 = (str: string) => {
  return window.btoa(window.unescape(encodeURIComponent(str)));
};

export const decodeFromBase64 = (str: string) => {
  const decodedString = window.atob(str);

  const decoder = new TextDecoder("utf-8");
  // @ts-ignore
  const cyrillicString = decoder.decode(new Uint8Array([...decodedString].map(c => c.charCodeAt(0))));

  return cyrillicString
}
