import makeRequest from "./makeRequest";
import { apiBaseurl } from "./baseurl";

export const FileApi = {
  getFile: async (
    filename: string,
    thumbnail: boolean = false
  ): Promise<Blob> => {
    const data = await makeRequest({
      url: apiBaseurl + "/files",
      params: {
        filename,
        thumbnail,
      },
      responseType: "blob",
    });

    // @ts-ignore
    return data as Blob;
  },
};
