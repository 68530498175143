import { FC, useState } from "react";

import { Input, Modal, Space } from "antd";
import { IssuesCloseOutlined } from "@ant-design/icons";

interface DeleteModalProps {
  open: boolean;
  onDelete: () => void;
  onCancel: () => void;
}

const DeleteModal: FC<DeleteModalProps> = ({ open, onDelete, onCancel }) => {
  const [step, setStep] = useState<1 | 2>(1);
  const [confirmWord, setConfirmWord] = useState("");

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      okButtonProps={{
        disabled: step === 2 && confirmWord.trim().toLowerCase() !== "так",
      }}
      okText={step === 1 ? "Так" : "Видалити"}
      onOk={step === 1 ? () => setStep(2) : () => onDelete()}
      cancelText={step === 1 ? "Ні" : "Скасувати"}
      title={
        <Space size="middle">
          <IssuesCloseOutlined style={{ fontSize: 20, color: "orangered" }} />
          {step === 1
            ? "Видалити"
            : 'Ви обрали ВИДАЛЕННЯ файлів. Підтвердіть видалення ввівши "Так" у полі знизу'}
        </Space>
      }
    >
      {step === 2 && (
        <Input
          value={confirmWord}
          onChange={(e) => setConfirmWord(e.target.value)}
        />
      )}
    </Modal>
  );
};

export default DeleteModal;
