import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { Layout } from "antd";
import { Content, Header } from "antd/es/layout/layout";

import { Archive, FileEditor } from "../../modules";
import { Nav } from "../../components";
import ErrorPage from "../ErrorPage";

const MainPage: FC = () => {
  return (
    <Layout>
      <Header style={{ background: "#fff", height: 70 }}>
        <Nav />
      </Header>
      <Content style={{ padding: 10, minHeight: "calc(100vh - 70px)" }}>
        <Routes>
          <Route path={"/*"} element={<Archive />} />
          <Route path={"files/:file"} element={<Archive />} />
          <Route path={"file/*"} element={<FileEditor />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Content>
    </Layout>
  );
};

export default MainPage;
