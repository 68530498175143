import { IFile } from "../../hooks/useArchive";
import { useMemo, useState } from "react";

const useSortFiles = (files: IFile[]) => {
  const [sort, setSort] = useState<"new" | "old" | "alphabet">();
  const sortedFiles = useMemo(() => {
    let filtered = files;
    if (sort) {
      if (sort === "new" || sort === "old") {
        const sortedByDate = files
          .slice()
          .sort(
            (file1, file2) =>
              new Date(file1.dateModified).getTime() -
              new Date(file2.dateModified).getTime()
          );
        filtered = sort === "old" ? sortedByDate : sortedByDate.reverse();
      } else if (sort === "alphabet") {
        filtered = files.slice().sort((file1, file2) => {
          if (file1.filename < file2.filename) {
            return -1;
          }
          if (file1.filename > file2.filename) {
            return 1;
          }
          return 0;
        });
      }
    }
    return filtered;
  }, [files, sort]);

  return { sortedFiles, setSort };
};

export default useSortFiles;
