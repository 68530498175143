import { FC } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./Nav.module.scss";

import { Button, Dropdown } from "antd";
import { LogoutOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import { clearAuthToken } from "../../utils/token";
import { apiBaseurl } from "../../api/baseurl";
import { isMobile } from "../../utils/isMobile";

const Nav: FC = () => {
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    clearAuthToken();
    navigate("/login");
  };

  return (
    <div className={styles.nav}>
      <img
        className={styles.logo}
        src={apiBaseurl + "/logo"}
        onError={(e) => {
          // @ts-ignore
          e.target.style.display = "none";
        }}
        alt="logo"
      />
      {isMobile() ? (
        <Dropdown
          trigger={["click"]}
          menu={{
            items: [
              {
                key: "logout",
                label: (
                  <Button
                    type={"link"}
                    icon={<LogoutOutlined />}
                    onClick={handleLogoutClick}
                  >
                    Вийти
                  </Button>
                ),
              },
            ],
          }}
        >
          <MenuUnfoldOutlined style={{ fontSize: 26 }} />
        </Dropdown>
      ) : (
        <Button icon={<LogoutOutlined />} onClick={handleLogoutClick}>
          Вийти
        </Button>
      )}
    </div>
  );
};

export default Nav;
