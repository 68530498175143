import { FC, FocusEvent, useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useNavigate } from "react-router-dom";

import {
  Dropdown,
  MenuProps,
  notification,
  Space,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  FolderOutlined,
  CopyOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import styles from "./File.module.scss";

import { IFile } from "../../hooks/useArchive";
import { isImageFileName } from "../../utils/isImageFileName";
import { getFilenameName } from "../../utils/getFilenameName";
import { DeleteModal, TooltipContent } from "./components";
import { FileApi } from "../../api/file";
import { downloadFile } from "../../utils/downloadFile";
import { decodeFromBase64 } from "../../utils/base64";

interface FileProps {
  file: IFile;
  onRename: (filename: string, destination: string) => void;
  onReplace: (filename: string, destination: string) => void;
  setCopyFile: (filename: string) => void;
  onDelete: (filename: string) => void;
}

const File: FC<FileProps> = ({
  file,
  onDelete,
  onRename,
  setCopyFile,
  onReplace,
}) => {
  const navigate = useNavigate();
  const name = getFilenameName(file.filename);

  const ref = useRef<HTMLDivElement>(null);
  const [renameMode, setRenameMode] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  const [, drop] = useDrop({
    accept: "type 1",
    canDrop: () => file.directory,
    drop: () => ({ name: file.filename }),
  });

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: "type 1",
      item: { text: "some text" },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
      end: async (item, monitor) => {
        const dropResult: { name: string } | null = monitor.getDropResult();
        if (dropResult && file.filename !== dropResult.name) {
          let destination;
          if (!dropResult.name) {
            destination = window.btoa(name);
          } else {
            destination = window.btoa(
              `${decodeFromBase64(dropResult.name)}\\${name}`
            );
          }
          onReplace(file.filename, destination);
        }
      },
    }),
    []
  );

  useEffect(() => {
    if (file.directory) {
      return;
    }

    (async () => {
      if (isImageFileName(decodeFromBase64(file.filename))) {
        const fileData = await FileApi.getFile(file.filename, true);
        if (fileData.type.includes("image")) {
          setImageUrl(URL.createObjectURL(fileData));
        }
      }
    })();
  }, [file]);

  const handleClick = () => {
    if (file.directory) {
      navigate("/files/" + file.filename);
    } else {
      if (isImageFileName(name)) {
        navigate("/file/" + file.filename);
      } else {
        notification.info({
          message: "Тип файлу не підтримується для редагування",
        });
      }
    }
  };

  const handleInputBlur = async (e: FocusEvent<HTMLInputElement>) => {
    if (e.target.value === name) {
      setRenameMode(false);
      return;
    }
    const value = e.target.value;
    const directory = window
      .atob(file.filename)
      .split("\\")
      .slice(0, -1)
      .join("\\");
    const newFilename = directory ? `${directory}\\${value}` : value;
    onRename(file.filename, window.btoa(newFilename));
    setRenameMode(false);
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const handleDownload = async () => {
    if (isImageFileName(decodeFromBase64(file.filename))) {
      const fileData = await FileApi.getFile(file.filename);
      downloadFile(URL.createObjectURL(fileData), name);
    }
  };

  drag(drop(ref));

  const contextItems: MenuProps["items"] = [
    {
      key: "edit",
      label: (
        <Space>
          <EditOutlined />
          Переіменувати
        </Space>
      ),
      onClick: () => setRenameMode(true),
    },
    {
      key: "copy",
      label: (
        <Space>
          <CopyOutlined />
          Копіювати
        </Space>
      ),
      onClick: () => setCopyFile(file.filename),
    },
    {
      key: "delete",
      label: (
        <Space>
          <DeleteOutlined />
          Видалити
        </Space>
      ),
      onClick: () => setDeleteModal(true),
    },
  ];

  !file.directory && contextItems.unshift({
    key: "download",
    label: (
      <Space>
        <DownloadOutlined />
        Завантажити
      </Space>
    ),
    onClick: handleDownload,
  },)

  return (
    <>
      <Tooltip title={<TooltipContent file={file} />}>
        <Dropdown menu={{ items: contextItems }} trigger={["contextMenu"]}>
          <div
            ref={ref}
            style={{ opacity }}
            className={styles.file}
            onClick={handleClick}
          >
            {file.directory ? (
              <FolderOutlined className={styles.icon} />
            ) : imageUrl ? (
              <img className={styles.preview} src={imageUrl} alt="" />
            ) : (
              <FileOutlined className={styles.icon} />
            )}
            {renameMode ? (
              <input
                onClick={(e) => e.stopPropagation()}
                onDrag={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onMouseMove={(e) => {
                  e.stopPropagation();
                }}
                style={{ width: "fit-content", zIndex: 10 }}
                autoFocus
                defaultValue={name}
                onBlur={handleInputBlur}
              />
            ) : (
              <span className={styles.name}>{name}</span>
            )}
          </div>
        </Dropdown>
      </Tooltip>
      {deleteModal && (
        <DeleteModal
          open={deleteModal}
          onDelete={() => onDelete(file.filename)}
          onCancel={() => setDeleteModal(false)}
        />
      )}
    </>
  );
};

export default File;
