import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { ErrorPage, LoginPage, MainPage } from "../../pages";

const Router: FC = () => {
  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route path={"/*"} element={<MainPage />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default Router;
