export const base64toFile = (
  base64String: string,
  type: string,
  filename: string
) => {
  const binaryString = window.atob(base64String);

  const arrayBuffer = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    arrayBuffer[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([arrayBuffer], { type });

  const file = new File([blob], filename, { type });

  return file;
};
