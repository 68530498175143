import React, { useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import { clearAuthToken, isTokenExpired } from "./utils/token";
import { Router } from "./components";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isTokenExpired()) {
      navigate("/login");
      clearAuthToken();
    }
  }, [navigate, location.search]);

  return <Router />;
};

export default App;
